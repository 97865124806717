import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { KakaoCustomOverlay, KakaoMap, KakaoMarker, KakaoPolyline } from "../../Component/Map/Kakao";
import { useInterval, usePosition } from "../../Hooks/Hooks";
import { Button, Spinner } from "reactstrap";
import TamraCancelModal from "./TamraCancelModal";
import LocationButton from "../../Component/LocationButton";
import { UnavailablePage } from "./TamraUnavailable";

const AVARAGE_VELOCITY = 30 * (50 / 3); // m/min

function TamraJungmunConfirm ({
    history,
    location,
    ...props
}) {
    const position = usePosition(true);

    const [load, setLoad] = useState('loading');

    const [carName, setCarName] = useState('');
    const [carId, setCarId] = useState('');
    const [serviceName, setServiceName] = useState(['','']);
    const [serviceId, setServiceId] = useState(undefined);
    const [numPassenger, setNumPassenger] = useState(0);
    const [serviceTime, setServiceTime] = useState();
    const [ETA, setETA] = useState(-1);

    const [carInfo, setCarInfo] = useState({});
    const [startLocation, setStartLocation] = useState({location: [], description: ''});
    const [endLocation, setEndLocation] = useState({location: [], description: ''});
    const [onBoard, setOnBoard] = useState(false);
    
    const [isValid, setIsValid] = useState(true);
    const [isCanceled, setIsCanceled] = useState(false);

    const [openCancelModal, setOpenCancelModal] = useState(false);

    const [center, setCenter] = useState([]);
    const [adpPath, setAdpPath] = useState([]);

    const [tmpPath, setTempPath] = useState([]);

    const getADPath = useCallback(async (departure, destination, service_name) => {
        throw new Error("Getting ADP path does not support anymore.");
        // let ad_path = await axios.post(`/adp/${service_name[0]}/${service_name[1]}/get-path`, {
        //     departure,
        //     destination,
        // }).then((res)=>{
        //     if (res.statusText === "OK" && !!res.data) {
        //         const { data } = res;
        //         const { goal, recommended } = data;
        //         return {
        //             goal: !!data.goal,
        //             goal_location: [goal?.location.latitude, goal?.location.longitude, goal?.theta],
        //             goal_path: goal?.path.map(point => [point.latitude, point.longitude]),
        //             goal_path_length: goal?.length,
        //             recommendation: !!data.recommendation,
        //             recommendated_goal: [recommended?.location.latitude, recommended?.location.longitude, recommended?.theta],
        //             recommendated_path: recommended?.path.map(point => [point.latitude, point.longitude]),
        //             recommendated_length: recommended?.length,
        //         }
        //     }
        // }).catch((err)=>{
        //     console.error("error while request GET to server", err);
        // });
        // return ad_path;
    }, []);

    const confirmFunction = useCallback((car_name, location, service, service_id) => {
        axios.get(`/api/${location}/${service}/confirm`, {
            params: { service_id, car_name },
        }).then( ({ data }) => {
            const {
                is_valid, car_location, car_heading,
                departure_location = [], destination_location = [],
                on_board,
            } = data;
            setIsValid(is_valid);
            if (is_valid) {
                setCarInfo({
                    car_location, car_heading
                });
                setStartLocation(location_obj => {
                    if (location_obj.location.join('-') === departure_location.join('-')) {
                        return location_obj;
                    }
                    return {
                        ...location_obj,
                        location: departure_location
                    }
                });
                setEndLocation(location_obj => {
                    if (location_obj.location.join('-') === destination_location.join('-')) {
                        return location_obj;
                    }
                    return {
                        ...location_obj,
                        location: destination_location
                    }
                });
                setOnBoard(on_board);
                setCenter(center_point => {
                    if (center_point.length < 2) {
                        return departure_location;
                    }
                    return center_point;
                });
            }
        }).catch(err => {
            setIsValid(false);
            setIsCanceled(false);
        });
    }, [])

    const confirmCallback = useCallback(() => {
        if (!!!carName || !!!serviceName) {
            return;
        }
        confirmFunction(carName, serviceName[0], serviceName[1], serviceId);
    }, [carName, serviceName, serviceId, confirmFunction]);

    const onCancel = useCallback(async () => {
        await axios.put(`/api/${serviceName[0]}/${serviceName[1]}/cancelCall`, {
            service_id: serviceId,
            car_name: carName,
        }).then( ({ data }) => {
            const { result_code } = data;
            setIsCanceled(true);
            // setIsCanceled(result_code == 1);
        }).catch(err => {
            if (err.response?.status === 400) {
                console.error("이미 완료되었거나 취소된 서비스 입니다.");
            }
            else if(err.response?.status === 403) {
                console.error("서비스 이름이 올바르지 않거나 차량이 다른 서비스를 운영중입니다.");
            }
            else if(err.response?.status === 404) {
                console.error("차량이 받은 호출이 없습니다.");
            }
            alert("호출을 취소하지 못했습니다.");
        });
    }, [carName, serviceName, serviceId]);




    useInterval(confirmCallback, 1000);

    useLayoutEffect(() => {
        async function wrapper() {
            const {
                id
            } = Object.fromEntries(new URLSearchParams(location.search));
            setServiceId(id);
            const {status, data} = await axios.get(`/api/tamra/jungmun/confirm-car`,{
                params: { id }
            })
            .then(res => {
                return res
            })
            .catch(err => {
                console.error(err);
                return err.response;
            });
            setLoad(status < 400 ? 'complete' : 'fail');
            if (status < 400) {
                const {
                    car_name, car_id, num_passenger, service_name, service_datetime, service_status,
                    departure, destination,
                } = data;
                const [location, service] = service_name.split('_').map(str => str.toLowerCase());
                confirmFunction(car_name, location, service, id);
                setCarName(car_name);
                setCarId(car_id);
                setServiceName([location, service]);
                setNumPassenger(num_passenger);
                setServiceTime(new Date(service_datetime));
                setIsCanceled(service_status === 'cancel');
                setStartLocation(location_obj => {
                    return {
                        ...location_obj,
                        description: departure,
                    }
                });
                setEndLocation(location_obj => {
                    return {
                        ...location_obj,
                        description: destination,
                    }
                });
            }
            else {
                setIsValid(false);
            }
        }
        wrapper();
    }, [location]);

    useLayoutEffect(() => {
        if (startLocation.location.length && endLocation.location.length && serviceName.every(name => !!name)) {
            getADPath(startLocation.location, endLocation.location, serviceName)
            .then(adp_path => setAdpPath(adp_path))
            .catch(err => console.log(err));
        }
    }, [startLocation, endLocation, serviceName, getADPath]);

    // useLayoutEffect(() => {
    //     const {
    //         car_location
    //     } = carInfo;
    //     if (car_location && startLocation.location.length && endLocation.location.length && serviceName.every(name => !!name)) {
    //         const start_location = onBoard ? startLocation.location : car_location;
    //         const end_location = onBoard ? endLocation.location : startLocation.location;
    //         getADPath(start_location, end_location, serviceName)
    //         .then(adp_path => {
    //             const length = adp_path.goal_path_length
    //             const eta = (length) / (AVARAGE_VELOCITY);
    //             setETA(Math.floor(eta));
    //             setTempPath(adp_path.goal_path)
    //         })
    //         .catch(err => console.log(err));
    //     }
    // }, [onBoard, carInfo, startLocation, endLocation, serviceName, getADPath]);
    useLayoutEffect(() => {
        const {
            car_location
        } = carInfo;
        if (car_location && startLocation.location.length && endLocation.location.length && serviceName.every(name => !!name)) {
            const start_location = car_location; //onBoard ? startLocation.location : car_location;
            const end_location = onBoard ? endLocation.location : startLocation.location;
            getADPath(start_location, end_location, serviceName)
            .then(adp_path => {
                const length = adp_path.goal_path_length;
                const eta = (length) / (AVARAGE_VELOCITY);
                setETA(Math.ceil(eta));
                setTempPath(adp_path.goal_path)
            })
            .catch(err => console.error(err));
        }
    }, [onBoard, carInfo, startLocation, endLocation, serviceName, getADPath]);
    
    return (load === 'loading' ? 
    <UnavailablePage
        history={history}
        black={true}
        disable
    >
        불러오는 중입니다 <br/>
        잠시만 기다려 주십시오 <Spinner />
    </UnavailablePage>
    : load === 'fail' ? 
    <UnavailablePage
        history={history}
        black={true}
    >
        Not Found: 404 <br/>
        올바르지 않은 경로입니다
    </UnavailablePage>
    : 
    !isValid ? 
        <UnavailablePage
            history={history}
            black={isCanceled}
        >
            {isCanceled ? <>호출이<br/> 취소되었습니다.</> : <>이용이<br/> 완료되었습니다.</>}
        </UnavailablePage>
    : <>
        <div className="font-SUIT" style={{position:"relative", height: "100%", display:"flex", flexDirection:"column-reverse"}}>
            <div className="tamra-bottom-box relative">
                <LocationButton
                    className="tamra-location-button"
                    onClick={()=>{
                        setCenter([position.latitude, position.longitude])
                    }}
                />
                <div className="reservation-title">
                차량이 이동중입니다.
                </div>
                <div className="line-divider" />
                <div className="info-box">
                    <div className="tamra-information-box">
                        <div>
                            <div>
                            경로
                            </div>
                            <div>
                                {`${startLocation.description} > ${endLocation.description}`}
                            </div>
                        </div>
                        <div>
                            <div>
                            탑승 인원
                            </div>
                            <div>
                                {numPassenger}인
                            </div>
                        </div>
                        <div>
                            <div>
                            탑승 시각/호출 시각
                            </div>
                            <div>
                                {serviceTime ? serviceTime.toLocaleString() : null}
                            </div>
                        </div>
                        <div className='list'>
                            <div>
                            차량 번호
                            </div>
                            <div>
                                {carId}
                            </div>
                        </div>
                        <div className='list'>
                            <div>
                                문의
                            </div>
                            <div>
                                064-748-8039
                            </div>
                        </div>
                    </div>
                    <div className="eta-box">
                        <span>도착 예정 시간</span>
                        <span>{ETA < 0 ? "invalid" : ETA}분</span>
                    </div>
                </div>
                <Button onClick={(e) => {
                    setOpenCancelModal(true)
                }}>
                호출 취소
                </Button>
            </div>
            <KakaoMap
                width="100%"
                style={{position: "relative", flexGrow: 1}}
                center={center}
                level={6}
            >
                <KakaoMarker 
                    position={startLocation.location}
                    image={{
                        src: `/get_on=${false ? 'green' : 'blue'}.svg`,
                        width: 48,
                        height: 48,
                        options: {
                            offset: [24, 40],
                        }
                    }}
                />
                <KakaoMarker 
                    position={endLocation.location}
                    image={{
                        src: `/get_off=${false ? 'green' : 'blue'}.svg`,
                        width: 48,
                        height: 48,
                        options: {
                            offset: [24, 40],
                        }
                    }}
                />
                <KakaoCustomOverlay
                    position={carInfo.car_location}
                    contentID="carIcon"
                >
                    <img
                        src="/car_icon=mini.png"
                        style={{
                            transform: `rotate(${
                                180 - (carInfo.car_heading ?? 90)
                            }deg)`,
                        }}
                    />
                </KakaoCustomOverlay>
                {position.hasOwnProperty("latitude") ? (
                    <KakaoCustomOverlay
                        position={position}
                        contentID="mylocation"
                    >
                        <img
                            src="/mylocation=direction.svg"
                            style={{
                                transform: `rotate(${
                                    90 - (position.heading ?? 90)
                                }deg)`,
                            }}
                        />
                    </KakaoCustomOverlay>
                ) : null}
                <KakaoPolyline
                    path={adpPath.goal_path}
                    strokeColor="#0023f3"
                />
                <KakaoPolyline
                    path={tmpPath}
                    strokeColor="#f00"
                />
            </KakaoMap>
        </div>
        <TamraCancelModal
            isReservation={true}
            isOpen={openCancelModal}
            centered={true}
            onCancel={onCancel}
            toggle={() => {setOpenCancelModal(p => !p)}}
        />
    </>)
};

export default withRouter(TamraJungmunConfirm);