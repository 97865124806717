import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { completedService, getGPSBtn } from "./SeogwipoCommon.js";
import { calcETA  } from '../../Component/Common.js';
import SeogwipoMapContainer from "./SeogwipoMapContent.js";
import { Button } from 'reactstrap';
import { IoIosArrowForward } from 'react-icons/io';
import axios from "axios";

class SeogwipoFloatingConfirmDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_valid: false,
            my_location: undefined,
            car_location: [33.2529452915, 126.510506814, 116.428604879],
            car_heading: 163.075883698,
            departure_location: undefined,
            destination_location: undefined,
            boarding_list: [undefined, undefined],
            current_path: undefined,
            global_path: undefined,
            current_path_length: undefined,
            global_path_length: undefined,
            on_board: false,
            departure_name: undefined,
            destination_name: undefined,

        };
        this.watch_id = undefined;
        this.geo_permission = undefined;
        this.map_container = React.createRef();
    }

    async getCallState() {
        // if(!!this.map_container.current && this.state.is_valid){
            try{
                if(!this.state.on_board){
                    // before car arrives boarding location
                    await this.getCarPath(this.state.departure_location
                    ).then((result)=>{
                        this.setState({
                            current_path: result.goal_path,
                            current_path_length: result.goal_path_length,
                        })
                    }).catch((err)=>{
                        console.error(err);
                    });
                    await this.map_container.current.updateIdleState();
                }
                else{
                    // after boarding location
                    await this.getCarPath(this.state.destination_location
                    ).then((result)=>{
                        this.setState({
                            global_path: result.goal_path,
                            global_path_length: result.goal_path_length,
                        })
                    }).catch((err)=>{
                        console.error(err);
                    });
                    await this.map_container.current.updateIdleState();
                }
            }catch(err){
                console.error("getCallState: kakao map is not loaded", err);
            }
        // }
    }

    async getCarPath(destination){
        return await axios.put("/demo/seogwipo/floating/get-car-path", {
            destination : destination,
        }).then((res)=>{
            if (res.statusText === "OK" && !!res.data) {
                return res.data
            }
        }).catch((err)=>{
            console.error("error while request PATH to server");
            console.log(err);
            return undefined;
        });
    }

    async getGlobalPath(departure, destination){
        let result;
        console.warn("get-path does not support anymore.");
        // await axios.post("/adp/seogwipo/floating/get-path", {
        //     departure: departure,
        //     destination : destination,
        // }
        // ).then((res)=>{
        //     if (res.statusText === "OK" && res.data !== undefined) {
        //         result = res.data;
        //     }
        // }).catch((err)=>{
        //     console.error("error while request GET to server", err);
        // });
        return result;
    }

    async getMyPosition(){
        if(!!this.watch_id) return;
        this.watch_id = navigator.geolocation.watchPosition((position)=>{
            this.geo_permission = 'granted';
            this.setState({
                ...this.state,
                my_location: [position.coords.latitude, position.coords.longitude]
            });
        }, (error)=>{
            this.watch_id = undefined;
            this.geo_permission = 'denied';
            this.setState({
                ...this.state,
                my_location: undefined,
            });
        });
    }
    
    async initAddressInfo(){
        if(!!this.map_container.current){
            let departure_name = await this.map_container.current.getAddress(this.state.departure_location);
            let destination_name = await this.map_container.current.getAddress(this.state.destination_location);
            this.setState({
                ...this.state,
                departure_name: departure_name,
                destination_name: destination_name,
            });
        }
    }

    async componentDidMount() {
        if(!!this.props.history.location.state){

            await this.getCarPath([this.props.history.location.state.departure_lat,this.props.history.location.state.departure_long]
                ).then((result)=>{
                    this.setState({
                        current_path: result.goal_path,
                        current_path_length: result.goal_path_length,
                    })
                }).catch((err)=>{
                    console.error(err);
                });
            this.setState({
                is_valid: true,
                departure_location: [this.props.history.location.state.departure_lat,this.props.history.location.state.departure_long],
                destination_location: [this.props.history.location.state.destination_lat, this.props.history.location.state.destination_long],
                boarding_list: this.props.history.location.state.boarding_list,
            });
        }

        // this.intervalId = await setInterval(() => {
        //     this.getCallState();
        //     // this.getMyPosition();
        // }, 2000);
        await this.getMyPosition();
        
        if(!!this.state.departure_location && !!this.state.destination_location){
            await this.initAddressInfo().catch(()=>{
                console.warn("kakao map has not loaded"); 
                setTimeout(this.initAddressInfo.bind(this),1000)
            });
            await this.getGlobalPath(this.state.departure_location, this.state.destination_location
                ).then((result)=>{
                this.setState({
                    global_path: result.goal_path,
                    global_path_length: result.goal_path_length,
                })
                }).catch((e)=>{
                    console.error("component mount error : ",e)
            });
        }
        try{
            navigator.permissions.query({name:'geolocation'})
            .then((permission_status) => {
                this.geo_permission = permission_status.state;
                permission_status.addEventListener("change", (e)=>{
                    if(!!!this.watch_id) this.getMyPosition();
                    this.geo_permission = e.target.state;
                })
            });
        } catch (e){
             console.error("Permission query is not supported by IOS.")
        }
    }
    async componentWillUnmount(){
        if(this.intervalId !== undefined)
            clearInterval(this.intervalId);
        navigator.geolocation.clearWatch(this.watch_id);
    }

    getInfoCard(){
        return(
            <div className="Card-Item floating confirm">
                <div className="title blue">  
                {this.state.on_board ? "승차하셨습니다. ": "호출이 완료되었습니다."}</div>
                <div className="title">
                {this.state.on_board ? "차량이 목적지로 이동합니다.": "차량이 승차 지점으로 이동합니다."}</div>
                <hr className="solid confirm"></hr>
                <div className="content">
                    <span className="pivot"> 경로 </span>
                    <span className="cell"> {this.state.departure_name}</span>
                    <IoIosArrowForward className="arrow-forward" /> 
                    <span className="cell"> {this.state.destination_name} </span>
                </div>
                <div className="content">
                    <span className="pivot"> 차량번호 </span>
                    <span className="cell"> 임 4192</span>
                </div>
                <div className="content">
                    <span className="pivot"> 운행요원 번호 </span>
                    <span className="cell"> 010-7499-8039 </span>
                </div>
                {this.getCancelBtn()}
            </div>
        );
    }
    getCancelBtn(){
        return(
            <Button className="cancel-btn" onClick={this.cancelBtnClickHandler.bind(this)}>
                호출 취소
            </Button>
        );
    }
    cancelBtnClickHandler(){
        if(window.confirm("호출을 취소하시겠습니까?")){
            this.props.history.goBack();
        }
    }
    
    async gpsClickHandler(){
        this.getMyPosition();
        if(!!this.map_container.current && this.geo_permission === 'granted'){
            this.map_container.current.setCenter(this.state.my_location);
        }
    }
    render() {
        const { is_valid, departure_location, destination_location, my_location, car_location, car_heading, 
                on_board, current_path, global_path, current_path_length, global_path_length} = this.state;
        if (is_valid) {
            return (
                <div className="main">
                    <SeogwipoMapContainer
                        ref={this.map_container}
                        draggable={true}
                        my_location = {my_location}
                        car_location = {car_location}
                        car_heading = {car_heading}
                        car_eta = {calcETA(on_board ? global_path_length : current_path_length)}
                        // start_location = {this.state.on_board ? undefined : my_location} 
                        departure_location = {on_board ? undefined : departure_location} 
                        destination_location = {destination_location}
                        car_to_departure_path = {on_board ? undefined : current_path}
                        global_path_list = {on_board ? global_path : undefined}
                        // global_path_list = { global_path }
                        // global_path_list = {current_path !== undefined && global_path !== undefined ? current_path.concat(global_path): undefined}
                        car_type="ioniq" />
                    <div className="Card-Item-wrapper">
                        {getGPSBtn(this.gpsClickHandler.bind(this), "confirm")}
                        {this.getInfoCard()}
                    </div>
                </div>
            );
        } else {
            return (completedService("이미 완료된 호출입니다.","문의 : 010-7499-8039",{path:"seogwipo/floating/demo"}));
        }
    }
}
export default withRouter(SeogwipoFloatingConfirmDemo);
