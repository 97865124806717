import React, { Component } from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import { floatingArea, getNoService, getOnService, getInitialInfo, floatingValidPath,
        inside, getBackwardBtn, floating_service_txt, getPressBtn, getGPSBtn } from "./SeogwipoCommon.js";
import { agreementText, agreementShortText, calcETA } from "../../Component/Common.js"
import { Jumbotron } from 'reactstrap';
import SeogwipoMapContainer from "./SeogwipoMapContent.js";
import SeogwipoMiniMapContainer from "./SeogwipoMiniMapContent.js";
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import {
    Button,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Toast,
} from "reactstrap";

import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { IoIosArrowForward } from 'react-icons/io';

const velocity = 20;
class SeogwipoPrivateFloating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            service_on: false,
            car_location: undefined,
            car_heading: undefined,
            call_available: false,
            request_started: false,
            departure_selected: false,
            destination_selected: false,
            request_checked: false,
            request_info_filled: false,
            call_succeed: false,
            my_location: undefined,
            start_location: [33.253322314394296, 126.5090085569142],
            end_location: [33.253322314394296, 126.5090085569142],
            boarding_location: [33.253322314394296, 126.5090085569142, 0.0], //[latitude, longitude, theta]
            departure_location: undefined,
            destination_location: undefined,
            boarding_list: [undefined, undefined], //[dep, des]
            location_validity: true,
            request_result: 'none',
            isModalOpened: false,
            numPassenger: 0,
            isAgreed: false,
            isAgreed2: false,
            isAgreed3: false,
            phoneValid1: true,
            phoneValid2: true,
            phoneValid3: true,
            // birthdateValid1: true,
            // birthdateValid2: true,
            // birthdateValid3: true,
            selectAlert: false,
            reset_map: false,
            valid_roads : [],   // valid road = {[latitude, longitude], ...}
            
            current_path:[],    // car to departure path
            current_path_length: -1,
            global_path:[],     // departure to destination path
            global_path_length: -1,
            toggle_recommend: false,
            planned_path: undefined,
            open_boarding_toast: false,

            cur_address: undefined,
            departure_address: undefined,
            destination_address: undefined,

            on_board: false,
            on_service_departure: undefined,
            on_service_destination: undefined,
        };
        
        this.unlisten = undefined;
        this.map_container = React.createRef();
        this.watch_id = undefined;
        this.geo_permission = undefined;
        this.page_location = "seogwipo_private_floating";
    }
    async getCallState() {
        await axios.get("/api/seogwipo/floating/getPrivateCallState", {
            params: { isClient: true }
        }).then(function (res) {
            if (res.statusText === "OK") {
                return res.data;
            } else {
                return {
                    service_on: false,
                    call_available: false,
                    car_location: undefined,
                    car_heading: undefined,
                    on_board: false,
                };
            }
        }).catch(function (error) {
            console.error("error while request GET to server");
            console.log(error);
            return {
                service_on: false,
                call_available: false,
                car_location: undefined,
                car_heading: undefined,
                on_board: false,
            };
        }).then((data) =>{
            this.setState({
                ...this.state,
                service_on: data.service_on,
                call_available: data.call_available,
                car_location: data.car_location,
                car_heading: data.car_heading,
                on_service_departure: data.current_service_departure,
                on_service_destination: data.current_service_destination, //data.current_service_destination,
                on_board: data.on_board,
            })
        });

        if(!!this.map_container.current){
            if(!this.state.call_available && !this.state.request_started && this.state.service_on){
                let path;
                try {
                    if(this.state.on_board){
                        path = await this.getCarPath(this.state.on_service_destination);
                    } else {
                        path = await this.getCarPath(this.state.on_service_departure);
                        let appened_path = await this.getGlobalPath(this.state.on_service_departure, this.state.on_service_destination);
                        path.goal_path = [...path.goal_path, ...appened_path.goal_path];
                        path.goal_path_length += appened_path.goal_path_length;
                    }
                    this.map_container.current.updateIdleState();
                } catch(e){
                    path = undefined;
                    console.error(e);
                }
                this.setState({
                    current_path: path?.goal_path,
                    current_path_length: path?.goal_path_length,
                });
            }
            else if(this.state.request_started){
                try{
                    this.map_container.current.updateIdleState();
                }catch(e){
                    console.error(e);
                }
            }
        }
    }
    async getCarPath(destination){
        return await axios.put("/adp/seogwipo/floating/get-car-path", {
            destination : destination,
        },{timeout: 3000}).then((res)=>{
            if (res.statusText === "OK" && !!res.data) {
                return res.data
            }
        }).catch((err)=>{
            console.error("error while request GET to server");
            console.log(err);
            return undefined;
        });
    }

    async getMyPosition(){
        if(!!this.watch_id) return;
        this.watch_id = navigator.geolocation.watchPosition((position)=>{
            this.geo_permission = 'granted';
            this.setState({
                ...this.state,
                my_location: [position.coords.latitude, position.coords.longitude]
            });
        }, (error)=>{
            this.watch_id = undefined;
            this.geo_permission = 'denied';
            this.setState({
                ...this.state,
                my_location: undefined,
            });
        });
    }

    async destinationCallback(departure, destination){
        let result;
        await this.getGlobalPath(departure, destination
        ).then((val)=>{
            result = val;
            this.setState({
                boarding_location: val.goal_location,
                global_path: val.goal_path,
                global_path_length: val.goal_path_length,
                planned_path: val,
                toggle_recommend: false,
            })
        }).catch((err)=>{
            console.error("Get global path error : ",err);
        });
        return result;
    }

    async departureCallback(departure){
        let result;
        await axios.post("/adp/point", {
            latitude: departure[0], longitude: departure[1]
        }).then((res)=>{
            if (res.statusText === "OK" && !!res.data) {
                result = res.data;
                this.setState({
                    boarding_location: [result.start_latitude, result.start_longitude],
                })
            }
        }).catch((err)=>{
            console.error("error while request GET to server");
            console.log(err);
        });
        return result;
    }

    async getClosestPoint(loc){
        await axios.post('/adp/point',loc,
        {
            timeout: 3000,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(function (res){
            if (res.statusText === "OK") {
                return res.data;
            }
            else{
                return undefined;
            }
        }).catch(function (e){
            console.error("error while POST to adp");
            console.log(e);

        }).then((data) =>{
            if(data !== undefined){
                this.setState({
                    ...this.state,
                    boarding_location: [data.start_latitude, data.start_longitude, data.start_theta],
                });
            }
        });
    }

    async getGlobalPath(departure, destination){
        let result;
        console.warn("get-path does not support anymore.");
        // await axios.post("/adp/seogwipo/floating/get-path", {
        //     departure: departure,
        //     destination : destination,
        // },{timeout: 3000}
        // ).then((res)=>{
        //     if (res.statusText === "OK" && !!res.data) {
        //         result = res.data;
        //     }
        // }).catch((err)=>{
        //     console.error("error while request GET to server");
        //     console.log(err);
        // })
        return result;
    }

    async componentDidMount() {
        await this.getCallState();
        await this.getMyPosition();
        this.intervalId = await setInterval(async () => {
            this.getCallState();
        }, 2000);
        // this.getValidRoad();
          
        navigator.geolocation.getCurrentPosition((position)=>{
            this.setState({
                ...this.state,
                start_location: [position.coords.latitude, position.coords.longitude],
            });
        });
        
        this.unlisten = this.props.history.listen(location => {
            if(this.props.history.action === "POP"){
                this.undoBtnCallback(location);
            }
        });
        try{
            navigator.permissions.query({name:'geolocation'})
            .then((permission_status) => {
                this.geo_permission = permission_status.state;
                permission_status.addEventListener("change", (e)=>{
                    this.geo_permission = e.target.state;
                })
            });
        } catch (e){
            console.error("Permission query is not supported by IOS.")
        }
    }
    async componentWillUnmount(){
        if(!!this.intervalId)
            clearInterval(this.intervalId);
        if(!!this.watch_id)
            navigator.geolocation.clearWatch(this.watch_id);
        if(!!this.unlisten)
            this.unlisten();
    }

    getToastBox(txt){
        return(
        <Toast className="Seogwipo-Floating-toast-box" isOpen={this.state.open_boarding_toast}>
            <div className="text">{txt}</div>
        </Toast>)
    }
    getLocationCard(btn_text, click_callback, valid_location, location_data){
        return(
            <div className="Card-Item floating">
                <div className="address">
                {!!location_data ? location_data["name"] : ""}
                </div>
                <div className="detail-address">
                {!!location_data ? location_data["address"] : ""}
                </div>
                {getPressBtn(btn_text,click_callback,"floating-select",!valid_location)}
            </div>
        );
    }
    getCheckCard(btn_text, click_callback){
        return(
            <div className="Card-Item floating fit">
                <div className="address-box">
                    <span className="left"> {this.state.departure_address["name"]} </span>
                    <IoIosArrowForward className="arrow-forward" />
                    <span className="right"> {this.state.destination_address["name"]} </span>
                </div>
                {getPressBtn(btn_text,click_callback,"floating-select")}
            </div>
        );
    }
    getPassengerBtn(n) {
        let default_name = "";
        let img = <img className = "passenger-icon" src={"/passenger_btn_icon.svg"}/>
        if (this.state.numPassenger === n) {
            default_name = "active";
            img = <img className = "passenger-icon" src={"/passenger_btn_icon_active.svg"}/>
        }
        return (
            <Col xs="4" className ={"column "+default_name}>
                <Button className ={"select-btn "+default_name}
                    onClick={() => this.updateNumPassenger(n)}>
                    {img} {n}명
                </Button>
            </Col>);
    }
    getInvalidAreaBtn(){
        return (
            <div className="invalid-warning-bg">
                <div className="invalid-warning-box">
                    <div className="title">
                    운행 가능 지역이 아닙니다.
                    </div>
                    <div className="button" onClick={this.gotoAreaCenter.bind(this)}>
                        운행 가능 지역으로 이동 <IoIosArrowForward className=""/>
                    </div>
                </div>
            </div>
        );
    }
    getValidAreaInfo(){
        return (
            <div className="valid-area-info-box">
                <div className="">
                    <div className="box" id="disable"/> 이용 불가 지역
                </div>
                <div className="">
                    <div className="box" id="able"/> 이동 가능 경로
                </div>
            </div>
        )
    }

    getRecommendBox(txt){
        if(!!!this.state.planned_path || !this.state.location_validity) return null;
        // let velocity = 20;
        // let txt = Math.ceil(Math.abs(this.state.planned_path.goal_path_length - this.state.planned_path.recommendated_length)/(velocity*1000/60));
        if(this.state.planned_path.recommendation){
            if(this.state.planned_path.goal_path_length >= this.state.planned_path.recommendated_length &&
                !!!calcETA(this.state.planned_path.goal_path_length - this.state.planned_path.recommendated_length,velocity)) return null;
            return (
                <div className="Seogwipo-Floating-toast-box recommend" >
                    <div className="text small">{txt}</div>
                    <div className="button" onClick={this.handleRecommendBtn.bind(this)}>{!this.state.toggle_recommend ? "변경하기" : "돌아가기"} <IoIosArrowForward className="arrow-forward"/></div>
                </div>
            )
        }
        return null;
    }
    async toggleRecommendBtn(){
        if(!this.state.toggle_recommend){
            this.setState((state) => ({
                toggle_recommend: !state.toggle_recommend,
                global_path: state.planned_path.recommendated_path,
                global_path_length: state.planned_path.recommendated_length,
                boarding_location: state.planned_path.recommendated_goal,
            }));
        }
        else{
            this.setState((state) => ({
                toggle_recommend: !state.toggle_recommend,
                global_path: state.planned_path.goal_path,
                global_path_length: state.planned_path.goal_path_length,
                boarding_location: state.planned_path.goal_location,
            }));
        }
    }
    async handleRecommendBtn(){
        if(!!this.map_container.current){
            await this.toggleRecommendBtn();
            await this.map_container.current.updateIdleState();
        }
    }

    storeState(state){
        this.props.history.replace(this.props.location.pathname,{...state});
    }

    undoBtnCallback(location){
        const { request_started, departure_selected, destination_selected, request_checked } = location.state;
        if(request_started === false){
            // to 호출 시작하기
            if(window.confirm("출도착지 설정화면을 나가시겠습니까?")){
                this.setState({
                    ...this.props.history.location.state,
                },()=>{if(!!this.map_container.current)this.map_container.current.showInitialCenter()});
            }
            else{
                this.props.history.goForward();
            }
        }
        else if(request_started === true){
            this.setState({
                ...this.props.history.location.state,
            },()=>{if(!!this.map_container.current)this.map_container.current.updateIdleState()});
        }
    }
    getCustomerInfo() {
        let btn = (
            <div className="customer-info-btn-wrapper">
                <div> 탑승객 수</div> 
                <Row className="customer-info-btn-box">
                    {this.getPassengerBtn(1)}
                    {this.getPassengerBtn(2)}
                    {this.getPassengerBtn(3)}
                </Row>
            </div>);

        return (
            <div className="form-wrapper">
                <p className="Seogwipo">승객 정보 입력하기</p>
                {btn}
                {!!this.state.numPassenger ? this.getForm() : <div />}
                {this.state.numPassenger >= 2 ? this.getForm2() : <div />}
                {this.state.numPassenger === 3 ? this.getForm3() : <div />}
                <span className="send-sms-info">※ &nbsp;예약 완료 시 안내 문자가 발송됩니다. </span>
                <div className="line-divider"/>
            </div>
        );
    }
    getAgreePage() {
        let modal = this.getAgreementModal();
        let agreeText = this.getAgreeText();
        let agreeText2 = this.getAgreeText2();
        let confirmBtn = this.getConfirmBtn();
        return (
            <div>
                <p className="Seogwipo">탑승 전 안내사항</p>
                <div className = "consent-form">
                    <Jumbotron className = "consent-form jumbotron">
                        {agreementShortText}
                        <Button className="btn-consent-form" 
                                onClick={this.toggle} 
                        >동의서 전문 확인하기 </Button>
                    </Jumbotron>
                </div>
                {modal}
                <div className="agree-box">
                    {agreeText}
                    {agreeText2}
                </div>
                <div >
                    {confirmBtn}
                </div>
            </div>);
    }
    checkPassengerNumSelect = e => {
        if (this.state.numPassenger === 0) {
            this.setState({ selectAlert: true });
        }
    }
    handlePhoneChange1 = e => {
        this.setState({ phone1: e.target.value });
        if (this.state.numPassenger === 1
            && !e.target.value.includes(" ")
            && !!e.target.value) {
            e.target.blur();
        } else if(this.state.numPassenger > 1
            && !e.target.value.includes(" ")
            && !!e.target.value){
            this.phoneInput2.focus();
        }
    }
    handlePhoneChange2 = e => {
        this.setState({ phone2: e.target.value });
        if (this.state.numPassenger === 2
            && !e.target.value.includes(" ")
            && !!e.target.value) {
            e.target.blur();
        }else if(this.state.numPassenger > 2
            && !e.target.value.includes(" ")
            && !!e.target.value){
            this.phoneInput3.focus();
        }
    }
    handlePhoneChange3 = e => {
        this.setState({ phone3: e.target.value });
        if (!e.target.value.includes(" ")
            && !!e.target.value) {
            e.target.blur();
        }
    }
    updateNumPassenger = e => {
        this.setState({ numPassenger: e, selectAlert: false });
    }

    handlePhoneBlur1 = e => {
        if (!e.target.value.includes(" ")
            && !!e.target.value) {
            this.setState({
                phoneValid1: true
            });
        } else {
            this.setState({
                phoneValid1: false
            });
        }
    }
    handlePhoneBlur2 = e => {
        if (!e.target.value.includes(" ")
            && !!e.target.value) {
            this.setState({
                phoneValid2: true
            });
        } else {
            this.setState({
                phoneValid2: false
            });
        }
    }
    handlePhoneBlur3 = e => {
        if (!e.target.value.includes(" ")
            && !!e.target.value) {
            this.setState({
                phoneValid3: true
            });
        } else {
            this.setState({
                phoneValid3: false
            });
        }
    }

    getForm() {
        return (
            <div className="form">
                <NumberFormat
                    customInput={TextField}
                    format="###-####-####"
                    error={!this.state.phoneValid1}
                    helperText={!this.state.phoneValid1 ? '11자리를 입력해주세요' : ''}
                    inputProps={{ ref: input => this.phoneInput1 = input }}
                    required
                    id={"passenger_phone1"}
                    label={"탑승자1 휴대전화"}
                    type="tel"
                    name={"phone1"}
                    fullWidth
                    onChange={this.handlePhoneChange1}
                    onFocus={this.checkPassengerNumSelect}
                    defaultValue={this.state.phone1}
                    onBlur={this.handlePhoneBlur1}
                    disabled={this.state.selectAlert} />
            </div>);
    }
    getForm2() {
        return (
            <div className="form">
                <NumberFormat
                    customInput={TextField}
                    format="###-####-####"
                    error={!this.state.phoneValid2}
                    helperText={!this.state.phoneValid2 ? '11자리를 입력해주세요' : ''}
                    inputProps={{ ref: input => this.phoneInput2 = input }}
                    required
                    id={"passenger_phone2"}
                    label={"탑승자2 휴대전화"}
                    type="tel"
                    name={"phone2"}
                    fullWidth
                    onChange={this.handlePhoneChange2}
                    onFocus={this.checkPassengerNumSelect}
                    defaultValue={this.state.phone2}
                    onBlur={this.handlePhoneBlur2}
                    disabled={this.state.selectAlert} />
            </div>);
    }
    getForm3() {
        return (
            <div className="form">
                <NumberFormat
                    customInput={TextField}
                    format="###-####-####"
                    error={!this.state.phoneValid3}
                    helperText={!this.state.phoneValid3 ? '11자리를 입력해주세요' : ''}
                    inputProps={{ ref: input => this.phoneInput3 = input }}
                    required
                    id={"passenger_phone3"}
                    label={"탑승자3 휴대전화"}
                    type="tel"
                    name={"phone3"}
                    fullWidth
                    onChange={this.handlePhoneChange3}
                    onFocus={this.checkPassengerNumSelect}
                    defaultValue={this.state.phone3}
                    onBlur={this.handlePhoneBlur3}
                    disabled={this.state.selectAlert} />
            </div>);
    }

    toggle = () => {
        this.setState({ isModalOpened: !this.state.isModalOpened });
    };
    getAgreementModal() {
        return (
            <Modal isOpen={this.state.isModalOpened} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>동의서 전문</ModalHeader>
                <ModalBody>{agreementText}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>확인</Button>
                </ModalFooter>
            </Modal>
        );
    }
    getAgreeText() {
        let check_icon = (this.state.isAgreed ? <MdCheckBox className="checkbox fill"/> : <MdCheckBoxOutlineBlank className="checkbox"/>);
        return (
            <Button id="agree-btn"
                onClick={() => { this.setState({ isAgreed: !this.state.isAgreed }) }}>{check_icon}
                 <span className={this.state.isAgreed ? "on": ""}>(필수) 동의사항을 숙지하였으며 이에 동의합니다.</span></Button>);
    }
    getAgreeText2() {
        let check_icon = (this.state.isAgreed2 ? <MdCheckBox className="checkbox fill"/> : <MdCheckBoxOutlineBlank className="checkbox"/>);
        return (
            <Button id="agree-btn"
                onClick={() => { this.setState({ isAgreed2: !this.state.isAgreed2 }) }}>{check_icon} 
                <span className={this.state.isAgreed2 ? "on": ""}>(필수) 개인정보 수집 및 이용에 동의합니다.</span></Button>);
    }
    getConfirmBtn() {
        let passengerInfoSet = false;
        // let no_adult = false;
        if (this.state.numPassenger === 1) {
            if (!!this.state.phone1 && this.state.phoneValid1) {
                passengerInfoSet = true;
            }
        } else if (this.state.numPassenger === 2) {
            if (!!this.state.phone1 && this.state.phoneValid1 && !!this.state.phone2 && this.state.phoneValid2
                && this.state.phone1 !== this.state.phone2) {
                passengerInfoSet = true;
            }
        } else if (this.state.numPassenger === 3) {
            if (!!this.state.phone1 && this.state.phoneValid1 && !!this.state.phone2 && this.state.phoneValid2 && !!this.state.phone3 && this.state.phoneValid3
                && this.state.phone1 !== this.state.phone2 && this.state.phone2 !== this.state.phone3 && this.state.phone1 !== this.state.phone3) {
                passengerInfoSet = true;
            }
        }
        // let with_child = isChild(this.state.birthdate1) || isChild(this.state.birthdate2) || isChild(this.state.birthdate3);
        if (passengerInfoSet && this.state.isAgreed && this.state.isAgreed2) {
        // if (passengerInfoSet && this.state.isAgreed && this.state.isAgreed2 && !no_adult && !with_child) {
            return (<Button className="press-btn Seogwipo" id = {this.state.call_available ? "able" : "disable"}
                    onClick={this.requestCall.bind(this)} form='passengerForm'>호출하기</Button>);
        } else {
            let warning_tex = "";
            let warning_tex2 = "";
            // if (no_adult) {
            //     warning_tex = <div style={{ color: "red", textAlign: "center" }}>* 미성년자 단독으로 탑승할 수 없습니다.</div>
            // }
            // if (with_child) {
            //     warning_tex2 = <div style={{ color: "red", textAlign: "center" }}>* 6세 이하는 탑승할 수 없습니다.</div>
            // }
            return (
            <div>{warning_tex}{warning_tex2}
                <Button className="press-btn Seogwipo" id = "disable"
                    form='passengerForm' disabled >호출하기</Button>
            </div>);
        }
    }

    requestCall = event => {
        if(!this.state.call_available){
            alert("현재 다른 탑승객이 이용 중입니다. 잠시 후에 다시 호출해주세요.");
            return;
        }
        event.preventDefault();
        axios.post("/api/seogwipo/floating/call_request",
            {
                'passenger_info':
                {
                    'phone1': this.state.phone1,
                    'phone2': this.state.phone2,
                    'phone3': this.state.phone3
                },
                'departure_lat': this.state.departure_location[0],
                'departure_long': this.state.departure_location[1],
                'destination_lat': this.state.destination_location[0],
                'destination_long': this.state.destination_location[1],
                'boarding_list': [this.state.start_location, this.state.end_location],
            }).then(function (res) {
                if (res.statusText === "OK" && res.data.requested) {
                    return { path: "/seogwipo/floating/confirm/" + res.data.service_id, requested_datetime: res.data.req_time, requested: res.data.requested };
                } else {
                    this.setState({ request_result: "none" });
                    return { path: "/seogwipo/invitation/floating", requested_datetime: new Date(), requested: res.data.requested };
                }
            }).catch(function (error) {
                console.error("error while request GET to server", error);
                if(error.response.status === 400 || error.response.status === 403){
                    // 사전 탑승 등록x or 승인x
                    alert("사전 탑승 등록된 정보가 아닙니다.")
                } else {
                    alert("제출 실패하였습니다. 다시 제출해주세요.");
                }
                return { path: "/seogwipo/floating", requested_datetime: new Date() };
            }).then((data) =>{
                if(data.requested){
                    this.setState({
                        ...this.state, request_result: data.path, requested_datetime: data.requested_datetime
                    })
                } else if(data.requested === false){
                    alert("현재 다른 탑승객이 이용 중입니다. 잠시 후에 다시 호출해주세요.");
                }
            }).catch(
                function (error) {
                    console.error(error);
                }
            );
        // ReactGA.event({
        //     category: 'User',
        //     action: 'Request Call'
        // });
    }

    startRequest() {
        this.storeState(this.state);
        this.setState({
            request_started: true,
            open_boarding_toast: true,
        },()=>{
            if(!!this.map_container.current && this.map_container.current.setLevel) this.map_container.current.setLevel(2);
            this.props.history.push(this.props.location.pathname,{...this.state});
        });
        setTimeout(()=>{
            this.setState({open_boarding_toast: false});
        },8000);
    }
    departureSelected() {
        if (this.state.location_validity) {
            this.storeState(this.state);
            this.setState({
                ...this.state,
                departure_location: this.state.boarding_location,
                departure_selected: true,
                departure_address: this.state.cur_address,
            },()=>{
                this.props.history.push(this.props.location.pathname,{...this.state})
            });
        }
    }
    destinationSelected() {
        if (this.state.location_validity) {
            this.storeState(this.state);
            this.setState({
                ...this.state,
                destination_location: this.state.boarding_location,
                destination_selected: true,
                destination_address: this.state.cur_address,
            },() =>{
                this.props.history.push(this.props.location.pathname,{...this.state});
                if(!!this.map_container.current){
                    this.map_container.current.drawCurrentBoardingLocation();
                    this.map_container.current.setBounds(this.state.departure_location, this.state.destination_location);
                }
            })
        }
    }
    checkSelected() {
        this.storeState(this.state)
        this.setState({
            ...this.state,
            request_checked: true,
        },()=> this.props.history.push(this.props.location.pathname,{...this.state}));
    }
    updateDepartureLocation = (loc) => {
        if (!this.state.departure_selected) {
            this.setState({
                reset_map: false,
                start_location: loc,
                location_validity: inside(loc, floatingArea)
            })
        }
    }
    updateDestinationLocation = (loc) => {
        if (!this.state.destination_selected) {
            this.setState({
                reset_map: false,
                end_location: loc,
                location_validity: inside(loc, floatingArea)
            });
        }
    }
    updateAddress = (address, place_name) =>{
        if(!!!address || !!!place_name) return;
        this.setState({
            cur_address: {name: place_name, address: address},
        });
    }

    gotoAreaCenter() {
        this.setState({
            reset_map: true,
        })
    }
    
    gpsClickHandler(){
        this.getMyPosition();
        if(!!this.map_container.current && this.geo_permission === 'granted'){
            this.map_container.current.setCenter(this.state.my_location);
        }
    }

    redirect(path) {
        sessionStorage.setItem("passenger_name", this.state.name1);
        sessionStorage.setItem("req_time", this.state.requested_datetime);
        clearInterval(this.intervalId);
        this.props.history.push(path);
    }

    render() {
        const { service_on, car_location, car_heading, call_available, request_started, departure_selected, destination_selected, request_checked, on_board,
                my_location, start_location, end_location, request_result, location_validity, reset_map, boarding_location, 
                departure_location, destination_location, current_path, global_path, cur_address, on_service_destination } = this.state;

        if(service_on === true && car_location !== undefined) {
            if (request_result !== 'none') {
                this.redirect(request_result);
                return (<div></div>);
            }
            let location_invalid_warning = <div />;
            if (!location_validity) {
                location_invalid_warning = this.getInvalidAreaBtn();
            }
            if (request_started === false) {
                if (call_available === false) {
                    let eta = calcETA(this.state.current_path_length, velocity);
                    return (
                        <div className="main flex">
                            {getOnService(eta)}
                            <div className="map-container">
                                <SeogwipoMapContainer 
                                    ref={this.map_container}
                                    draggable={true}
                                    my_location={this.geo_permission === "granted" ? my_location : undefined}
                                    opacity={"1.0"}
                                    area_data={floatingArea}
                                    car_location={car_location}
                                    car_heading={car_heading}
                                    car_type="ioniq"
                                    reset_map={reset_map}
                                    car_to_departure_path = {current_path}
                                    leaving_location = {on_service_destination}
                                    ETA = {eta}
                                />
                            </div>
                            <div style={{bottom:0}}/>
                            {getGPSBtn(this.gpsClickHandler.bind(this),"bottom-right")}
                        </div>
                    );
                } 
                return (
                    <div className="main">
                        <SeogwipoMapContainer
                            ref={this.map_container}
                            draggable={true}
                            my_location={this.geo_permission === "granted" ? my_location : undefined}
                            opacity={"1.0"}
                            area_data={floatingArea}
                            car_location={car_location}
                            car_heading={car_heading}
                            car_type="ioniq" 
                            use_center={false} 
                            valid_roads={floatingValidPath}
                        />
                        {this.getValidAreaInfo()}
                        {getPressBtn("호출 시작하기", this.startRequest.bind(this), "floating-main")}
                    </div>);
            } else if (request_started === true && departure_selected === false) {
                return (
                    <div className="main">
                        <SeogwipoMapContainer
                            ref={this.map_container}
                            draggable={true}
                            my_location={this.geo_permission === "granted" ? my_location : undefined}
                            opacity={"1.0"}
                            area_data={floatingArea}
                            car_location={car_location}
                            car_heading={car_heading}
                            car_type="ioniq"
                            initial_center={start_location}
                            use_center={true}
                            valid_roads={floatingValidPath}
                            reset_map={reset_map}
                            boarding_point = {boarding_location}
                            // car_to_departure_path = {current_path}
                            centerChangeCallback={this.updateDepartureLocation.bind(this)}
                            idleCallback={this.departureCallback.bind(this)}
                            addressHandler={this.updateAddress.bind(this)}
                            center_type="departure" />
                        {location_invalid_warning}
                        {this.getValidAreaInfo()}
                        {getBackwardBtn(this.props.history.goBack.bind(this),"right")}
                        {this.getToastBox("승차 아이콘 위치로 차량이 도착합니다.")}
                        <div className="Card-Item-wrapper">
                            {getGPSBtn(this.gpsClickHandler.bind(this))}
                            {this.getLocationCard("출발지로 선택", this.departureSelected.bind(this), location_validity, cur_address)}
                        </div>
                    </div>);
            } else if (request_started === true && departure_selected === true && destination_selected === false) {
                return (
                    <div className="main">
                        <SeogwipoMapContainer
                            ref={this.map_container}
                            draggable={true}
                            my_location={this.geo_permission === "granted" ? my_location : undefined}
                            opacity={"1.0"}
                            area_data={floatingArea}
                            car_location={car_location}
                            car_heading={car_heading}
                            car_type="ioniq"
                            initial_center={end_location}
                            use_center={true}
                            valid_roads={floatingValidPath}
                            reset_map={reset_map}
                            boarding_point = {boarding_location}
                            departure_location = {departure_location}
                            global_path_list = {global_path}
                            centerChangeCallback={this.updateDestinationLocation.bind(this)}
                            idleCallback={this.destinationCallback.bind(this, departure_location)}
                            addressHandler={this.updateAddress.bind(this)}
                            center_type="arrival" />
                        {location_invalid_warning}
                        {this.getValidAreaInfo()}
                        {getBackwardBtn(this.props.history.goBack.bind(this),"right")}
                        {this.getRecommendBox("도착지점을 맞은 편으로 변경 시 더욱 빠른 이동이 가능합니다.")}
                        <div className="Card-Item-wrapper">
                            {getGPSBtn(this.gpsClickHandler.bind(this))}
                            {this.getLocationCard("도착지로 선택", this.destinationSelected.bind(this), location_validity, cur_address)}
                        </div>
                    </div>);
            } else if(request_started === true && departure_selected === true && destination_selected === true && request_checked === false){
                let eta = calcETA(this.state.global_path_length, velocity);
                return (
                    <div className="main">
                        <SeogwipoMapContainer
                            ref={this.map_container}
                            draggable={true}
                            my_location={this.geo_permission === "granted" ? my_location : undefined}
                            opacity={"1.0"}
                            area_data={floatingArea}
                            car_location={car_location}
                            car_heading={car_heading}
                            car_type="ioniq"
                            use_center={false}
                            valid_roads={floatingValidPath}
                            reset_map={reset_map}
                            departure_location = {departure_location}
                            destination_location = {destination_location}
                            global_path_list = {global_path}
                            ETA = {eta} />
                        {this.getValidAreaInfo()}
                        {getBackwardBtn(this.props.history.goBack.bind(this),"right")}
                        <div className="Card-Item-wrapper">
                            {getGPSBtn(this.gpsClickHandler.bind(this),"floating")}
                            {this.getCheckCard("경로 확인", this.checkSelected.bind(this))}
                        </div>
                    </div>);
            }
            else {
                let eta = calcETA(this.state.global_path_length, velocity);
                return (<div className="main">
                        <SeogwipoMiniMapContainer departure={departure_location} destination= {destination_location}
                                                    global_path_list = {global_path} ETA={eta}/>
                        {getBackwardBtn(this.props.history.goBack.bind(this),"right")}
                        {getInitialInfo()}
                        {this.getCustomerInfo()}
                        {this.getAgreePage()}
                </div>);
            }
        } 
        else {
            return (getNoService(floating_service_txt));
        }
    }
}
export default withRouter(SeogwipoPrivateFloating);
